import React from 'react';
import Image from './image'

const VCard = (props) => {

    let {title, text} = props;

    return (
        <div className="card vcard" style={props.style}>
            <div className="vcard-item vcard-item-image">
                <Image filename="gespraechsetting.jpg" />
            </div>
            <div className="vcard-item vcard-item-content">
                {title && 
                    <h1>{title}</h1>
                }
                {text &&
                    <p>{text}</p>
                }
            </div>
        </div>
    )
}
export default VCard