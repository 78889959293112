import ImgSelbstvertrauen from "../../../../src/page_content/images/selbstvertrauen";
import ImgDerWeg from "../../../../src/page_content/images/der_weg_ist_das_ziel";
import Image from "../../../../src/components/image";
import Caption from "../../../../src/components/caption";
import React from 'react';
export default {
  ImgSelbstvertrauen,
  ImgDerWeg,
  Image,
  Caption,
  React
};