import VCard from "../../../../src/components/vcard";
import { Link } from 'gatsby';
import Image from "../../../../src/components/image";
import Caption from "../../../../src/components/caption";
import React from 'react';
export default {
  VCard,
  Link,
  Image,
  Caption,
  React
};