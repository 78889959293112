import ImgKinderBeimMalen from "../../../../src/page_content/images/kinder_beim_malen";
import ImgSchiffgrafik from "../../../../src/page_content/images/schiffgrafik";
import ImgStufenbild from "../../../../src/page_content/images/stufenbild";
import Image from "../../../../src/components/image";
import Caption from "../../../../src/components/caption";
import React from 'react';
export default {
  ImgKinderBeimMalen,
  ImgSchiffgrafik,
  ImgStufenbild,
  Image,
  Caption,
  React
};