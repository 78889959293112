import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "225px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "135.8108108108108%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAP/2gAMAwEAAhADEAAAAX2TFXJjHM6tGpEeX//EABoQAAIDAQEAAAAAAAAAAAAAAAABAhESEyH/2gAIAQEAAQUC7HRt6I5SVa2hWyi0Isj6v//EABgRAQEAAwAAAAAAAAAAAAAAAAAREkFx/9oACAEDAQE/Aeqz0r//xAAYEQADAQEAAAAAAAAAAAAAAAAAESEQEv/aAAgBAgEBPwF2ZwI//8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERIRAgQTH/2gAIAQEABj8C6dx4xSmUihzpZ//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExUWFx/9oACAEBAAE/IRPNPJiWTyXObv5BaVYilDqJOIexqFTYJCr2UR1UUrsGmmf/2gAMAwEAAgADAAAAEBvFDf/EABgRAQEBAQEAAAAAAAAAAAAAAAEAESFR/9oACAEDAQE/EDEmCeH1av/EABgRAQEBAQEAAAAAAAAAAAAAAAEAETGx/9oACAECAQE/EDl6sWCY2L//xAAdEAEAAwACAwEAAAAAAAAAAAABABEhMUFRYaHw/9oACAEBAAE/EGSlnFx+wTJWlSq9xbTXZbwAh9o6wQ4FYW3x6mwJoLYuwawXiI0pyTucQtD94iKmXrUstcwGuRduf//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/f955c92ade07c3791584f24c76446b0b/0467c/der-weg-ist-das-ziel.webp 148w", "/static/f955c92ade07c3791584f24c76446b0b/f6f22/der-weg-ist-das-ziel.webp 225w"],
            "sizes": "(max-width: 225px) 100vw, 225px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/f955c92ade07c3791584f24c76446b0b/c4d45/der-weg-ist-das-ziel.jpg 148w", "/static/f955c92ade07c3791584f24c76446b0b/e1ae0/der-weg-ist-das-ziel.jpg 225w"],
            "sizes": "(max-width: 225px) 100vw, 225px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/f955c92ade07c3791584f24c76446b0b/e1ae0/der-weg-ist-das-ziel.jpg",
            "alt": "Wie Kinder heute wachsen Grafik",
            "title": "Wie Kinder heute wachsen Grafik",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      