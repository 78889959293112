import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "187px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75.67567567567568%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBP/EABYBAQEBAAAAAAAAAAAAAAAAAAMBAv/aAAwDAQACEAMQAAABd40aSZDV/8QAGRAAAgMBAAAAAAAAAAAAAAAAAQIAEiER/9oACAEBAAEFApdRGI6x20L7/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAREv/aAAgBAwEBPwHALsv/xAAYEQACAwAAAAAAAAAAAAAAAAAAAQIREv/aAAgBAgEBPwFwtmT/xAAZEAACAwEAAAAAAAAAAAAAAAAQIQABETH/2gAIAQEABj8CdHYuD//EABkQAQEBAQEBAAAAAAAAAAAAAAERACExgf/aAAgBAQABPyGxwgq3Jqe4BKv3I4iExXzf/9oADAMBAAIAAwAAABC3z//EABcRAAMBAAAAAAAAAAAAAAAAAAARIQH/2gAIAQMBAT8QqaxEP//EABcRAAMBAAAAAAAAAAAAAAAAAAABEWH/2gAIAQIBAT8QRAWj/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARMSFBUfD/2gAIAQEAAT8Qv4CrBl/XW7UQhV0X2CEnzJUEEc7b7h7FuDQT/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/f73d0e79ed5ce0edf439ad786050085d/0467c/selbstvertrauen.webp 148w", "/static/f73d0e79ed5ce0edf439ad786050085d/54634/selbstvertrauen.webp 187w"],
            "sizes": "(max-width: 187px) 100vw, 187px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/f73d0e79ed5ce0edf439ad786050085d/c4d45/selbstvertrauen.jpg 148w", "/static/f73d0e79ed5ce0edf439ad786050085d/b5b83/selbstvertrauen.jpg 187w"],
            "sizes": "(max-width: 187px) 100vw, 187px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/f73d0e79ed5ce0edf439ad786050085d/b5b83/selbstvertrauen.jpg",
            "alt": "Gemälde: Selbstvertrauen",
            "title": "Gemälde: Selbstvertrauen",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      