import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.89189189189189%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAAbZZDWVMCf/EABwQAAICAgMAAAAAAAAAAAAAAAEDAAIEEhMhMf/aAAgBAQABBQLH42xiUaDxPd0k3IE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8BR//EABwQAAEFAAMAAAAAAAAAAAAAAAEAAhESMRAhQf/aAAgBAQAGPwKTuQU59cXbio8VC4xx/8QAGhABAQEBAQEBAAAAAAAAAAAAAREAITFRgf/aAAgBAQABPyF8EcQrAV4y6fAwgvpfwyahEB7MDaD2b//aAAwDAQACAAMAAAAQKx//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFB/9oACAEDAQE/EJrZT//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCVp//EAB0QAQEAAgIDAQAAAAAAAAAAAAERADEhQVGBsfD/2gAIAQEAAT8QZtGnerQ1+cmAkL0PHvGKdeQF1gAhQTvYR6xPRgOWUS5YsCFOjg+Z/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/ca2ebe52425d7d76ac8b176805020d0c/0467c/stufenbild.webp 148w", "/static/ca2ebe52425d7d76ac8b176805020d0c/302d7/stufenbild.webp 295w", "/static/ca2ebe52425d7d76ac8b176805020d0c/80ee0/stufenbild.webp 590w", "/static/ca2ebe52425d7d76ac8b176805020d0c/38d72/stufenbild.webp 885w", "/static/ca2ebe52425d7d76ac8b176805020d0c/1a946/stufenbild.webp 1180w", "/static/ca2ebe52425d7d76ac8b176805020d0c/ce35d/stufenbild.webp 3500w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/ca2ebe52425d7d76ac8b176805020d0c/c4d45/stufenbild.jpg 148w", "/static/ca2ebe52425d7d76ac8b176805020d0c/1f0d7/stufenbild.jpg 295w", "/static/ca2ebe52425d7d76ac8b176805020d0c/625aa/stufenbild.jpg 590w", "/static/ca2ebe52425d7d76ac8b176805020d0c/f8367/stufenbild.jpg 885w", "/static/ca2ebe52425d7d76ac8b176805020d0c/fab84/stufenbild.jpg 1180w", "/static/ca2ebe52425d7d76ac8b176805020d0c/55e9d/stufenbild.jpg 3500w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/ca2ebe52425d7d76ac8b176805020d0c/625aa/stufenbild.jpg",
            "alt": "Stufenbild",
            "title": "Stufenbild",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      