module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Praxis für kreative Lebensgestaltung","short_name":"Wolfgang Engelhardt","start_url":"/","background_color":"#495C20","theme_color":"#495C20","display":"standalone","icon":"src/images/logo.png","orientation":"portrait","include_favicon":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":590,"linkImagesToOriginal":false,"quality":75,"withWebp":true}}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
