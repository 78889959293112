import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70.94594594594594%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGQAAAQUAAAAAAAAAAAAAAAAAAAECAwQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB24bCDwD/xAAaEAEAAQUAAAAAAAAAAAAAAAABAgADEBES/9oACAEBAAEFApOiFzppz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABUQAQEAAAAAAAAAAAAAAAAAAAEg/9oACAEBAAY/Amv/xAAZEAEBAAMBAAAAAAAAAAAAAAABABEhQTH/2gAIAQEAAT8hRB6cm1YSLaA4Yi//2gAMAwEAAgADAAAAEKMP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFBof/aAAgBAQABPxDWMLs9hNuL0WJQvsAFOn2OKKngZBk//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/f3b1723eb3fa2676785f807617868f2d/0467c/schiffgrafik.webp 148w", "/static/f3b1723eb3fa2676785f807617868f2d/302d7/schiffgrafik.webp 295w", "/static/f3b1723eb3fa2676785f807617868f2d/80ee0/schiffgrafik.webp 590w", "/static/f3b1723eb3fa2676785f807617868f2d/38d72/schiffgrafik.webp 885w", "/static/f3b1723eb3fa2676785f807617868f2d/1a946/schiffgrafik.webp 1180w", "/static/f3b1723eb3fa2676785f807617868f2d/020db/schiffgrafik.webp 1754w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/f3b1723eb3fa2676785f807617868f2d/c4d45/schiffgrafik.jpg 148w", "/static/f3b1723eb3fa2676785f807617868f2d/1f0d7/schiffgrafik.jpg 295w", "/static/f3b1723eb3fa2676785f807617868f2d/625aa/schiffgrafik.jpg 590w", "/static/f3b1723eb3fa2676785f807617868f2d/f8367/schiffgrafik.jpg 885w", "/static/f3b1723eb3fa2676785f807617868f2d/fab84/schiffgrafik.jpg 1180w", "/static/f3b1723eb3fa2676785f807617868f2d/8fcb5/schiffgrafik.jpg 1754w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/f3b1723eb3fa2676785f807617868f2d/625aa/schiffgrafik.jpg",
            "alt": "Wie Kinder heute wachsen Grafik",
            "title": "Wie Kinder heute wachsen Grafik",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      