import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.89189189189189%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABQABAv/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABS4J3O14WT//EABkQAQEBAAMAAAAAAAAAAAAAAAIBAwARIf/aAAgBAQABBQJLzLSMrUG6Oynum2c//8QAFREBAQAAAAAAAAAAAAAAAAAAEQD/2gAIAQMBAT8BGL//xAAVEQEBAAAAAAAAAAAAAAAAAAARAP/aAAgBAgEBPwFJv//EABkQAQEBAQEBAAAAAAAAAAAAAAEAESEyYf/aAAgBAQAGPwJ+XWxbjlq3kv/EABoQAQEBAQADAAAAAAAAAAAAAAERACFRYXH/2gAIAQEAAT8hClemT5kcnc+3W7J7R1hK+8hqq+Tf/9oADAMBAAIAAwAAABBk7//EABcRAQADAAAAAAAAAAAAAAAAAAABEUH/2gAIAQMBAT8QjFd//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxBx0g//xAAeEAEAAgIBBQAAAAAAAAAAAAABABEhMUFRcaHR8P/aAAgBAQABPxChSsBYezmDbFhWlx7muBGq6xGrsmVbrxLRMI7/AG4ry8Wxn//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/931455563c705717395c966370591e7d/0467c/kinder_beim_malen.webp 148w", "/static/931455563c705717395c966370591e7d/302d7/kinder_beim_malen.webp 295w", "/static/931455563c705717395c966370591e7d/80ee0/kinder_beim_malen.webp 590w", "/static/931455563c705717395c966370591e7d/38d72/kinder_beim_malen.webp 885w", "/static/931455563c705717395c966370591e7d/1a946/kinder_beim_malen.webp 1180w", "/static/931455563c705717395c966370591e7d/ce35d/kinder_beim_malen.webp 3500w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/931455563c705717395c966370591e7d/c4d45/kinder_beim_malen.jpg 148w", "/static/931455563c705717395c966370591e7d/1f0d7/kinder_beim_malen.jpg 295w", "/static/931455563c705717395c966370591e7d/625aa/kinder_beim_malen.jpg 590w", "/static/931455563c705717395c966370591e7d/f8367/kinder_beim_malen.jpg 885w", "/static/931455563c705717395c966370591e7d/fab84/kinder_beim_malen.jpg 1180w", "/static/931455563c705717395c966370591e7d/55e9d/kinder_beim_malen.jpg 3500w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/931455563c705717395c966370591e7d/625aa/kinder_beim_malen.jpg",
            "alt": "Kinder beim Malen",
            "title": "Kinder beim Malen",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      